<template>
  <div class="years-page">
    <div class="header">
      <div class="monthLbl button noselect">
        {{ yearsLbl }}
      </div>
      <div class="navigate">
        <router-link :to="lastDecadePath" class="button noselect">
          <i class="arrow left"></i>
        </router-link>
        <router-link :to="nextDecadePath" class="button noselect">
          <i class="arrow right"></i>
        </router-link>
      </div>
    </div>
    <div class="years">
      <router-link
        class="year button noselect"
        v-for="year of years"
        :key="'year' + year"
        :to="'/year/' + year"
      >
        {{ year }}
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Years",
  mounted() {},
  computed: {
    decade(): number | undefined {
      const yearInput = this.$route.params.years as string;
      if (!yearInput || !/^\d+x$/.test(yearInput as string)) {
        return undefined;
      }
      return Number(yearInput.replace("x", ""));
    },
    lastDecade(): number | undefined {
      if (this.decade === undefined) {
        return undefined;
      }
      return this.decade - 1;
    },
    lastDecadePath(): string | undefined {
      if (this.lastDecade === undefined) {
        return undefined;
      }
      return `/years/${this.lastDecade}x`;
    },
    nextDecade(): number | undefined {
      if (this.decade === undefined) {
        return undefined;
      }
      return this.decade + 1;
    },
    nextDecadePath(): string | undefined {
      if (this.nextDecade === undefined) {
        return undefined;
      }
      return `/years/${this.nextDecade}x`;
    },
    yearsLbl(): string | undefined {
      const yearInput = this.$route.params.years as string;
      if (!yearInput) {
        return undefined;
      }
      return "Những năm " + yearInput.replace("x", "0");
    },
    years(): Number[] | undefined {
      const yearInput = this.$route.params.years as string;
      if (!yearInput || !/^\d+x$/.test(yearInput as string)) {
        return undefined;
      }
      const years = [];
      for (let index = 0; index < 10; index++) {
        years.push(Number(yearInput.replace("x", index + "")));
      }
      return years;
    },
  },
});
</script>
<style scoped>
.years {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.year {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 50%;
}
</style>