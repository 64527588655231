
import { defineComponent } from "vue";

export default defineComponent({
  name: "Years",
  mounted() {},
  computed: {
    decade(): number | undefined {
      const yearInput = this.$route.params.years as string;
      if (!yearInput || !/^\d+x$/.test(yearInput as string)) {
        return undefined;
      }
      return Number(yearInput.replace("x", ""));
    },
    lastDecade(): number | undefined {
      if (this.decade === undefined) {
        return undefined;
      }
      return this.decade - 1;
    },
    lastDecadePath(): string | undefined {
      if (this.lastDecade === undefined) {
        return undefined;
      }
      return `/years/${this.lastDecade}x`;
    },
    nextDecade(): number | undefined {
      if (this.decade === undefined) {
        return undefined;
      }
      return this.decade + 1;
    },
    nextDecadePath(): string | undefined {
      if (this.nextDecade === undefined) {
        return undefined;
      }
      return `/years/${this.nextDecade}x`;
    },
    yearsLbl(): string | undefined {
      const yearInput = this.$route.params.years as string;
      if (!yearInput) {
        return undefined;
      }
      return "Những năm " + yearInput.replace("x", "0");
    },
    years(): Number[] | undefined {
      const yearInput = this.$route.params.years as string;
      if (!yearInput || !/^\d+x$/.test(yearInput as string)) {
        return undefined;
      }
      const years = [];
      for (let index = 0; index < 10; index++) {
        years.push(Number(yearInput.replace("x", index + "")));
      }
      return years;
    },
  },
});
